import { fieldTypeEmail } from '@/helpers/validations'
import { i18n } from '@/i18n'

export default {
  firstName: [{ required: true, message: 'Ingresa tus nombres', trigger: 'blur' }],
  lastName: [{ required: true, message: 'Ingresa tus apellidos', trigger: 'blur' }],
  company: {
    type: 'object',
    required: true,
    options: { first: true },
    fields: {
      companyName: { required: true, message: 'Ingresa el nombre de tu empresa', trigger: 'blur' },
      identificationTypeId: { required: true, message: 'Debe seleccionar un tipo de identificación', trigger: ['blur', 'change'] },
      companyIdentification: { required: true, message: 'Ingresa número de identificación', trigger: 'blur' },
    },
  },

  // companyName: [{ required: true, message: 'Ingresa el nombre de tu empresa', trigger: 'blur' }],
  // identificationTypeId: [{ required: true, message: 'Debe seleccionar un tipo de identificación', trigger: ['blur', 'change'] }],
  // companyIdentification: [{ required: true, message: 'Ingresa número de identificación', trigger: 'blur' }],
  email: [{
    type: 'email', required: true, message: 'Ingresa tu e-mail', trigger: 'blur',
  }],
  phone: [{
    required: true, pattern: /^[0-9]*$/, message: 'Ingresa el teléfono, sin espacios, unicamente números', trigger: 'blur',
  }, {
    min: 10, max: 10, message: 'El telefono debe contener 10 números', trigger: 'blur',
  }],
  person: {
    personName: [{ required: true, message: i18n.tc('enterField', 1, { field: i18n.tc('companyName', 2) }) }],
    personFirstName: [{ required: true, message: i18n.tc('enterField', 1, { field: i18n.tc('firstName', 2) }) }],
    personFirstSurname: [{ required: true, message: i18n.tc('enterField', 1, { field: i18n.tc('surname', 2) }) }],
    identificationTypeCode: [{ required: true, message: i18n.tc('enterField', 1, { field: i18n.tc('identificationType', 2) }) }],
    personIdentification: [{ required: true, message: i18n.tc('enterField', 1, { field: i18n.tc('identification', 2) }) }],
    personIdentificationCheck: '',
    personType: [{ required: true, message: i18n.tc('selectFile', 1, { file: i18n.tc('personType', 2) }) }],
  },
}

export const personAddress = {
  addressName: [{ required: true, message: i18n.tc('fieldRequired', 1, { field: i18n.tc('name', 2) }) }],
  addressLine: [{ required: true, message: i18n.tc('enterField', 1, { field: i18n.tc('address', 1) }) }],
  countryName: [{ required: true, message: i18n.tc('enterField', 1, { field: i18n.tc('country', 1) }) }],
  regionName: [{ required: true, message: i18n.tc('enterField', 1, { field: i18n.tc('region', 1) }) }],
  cityName: [{ required: true, message: i18n.tc('enterField', 1, { field: i18n.tc('city', 1) }) }],
  addressPhone: [{ required: true, message: i18n.tc('enterField', 1, { field: i18n.tc('phone', 1) }) }],
}

export const personEmail = {
  email: [{ required: true, message: i18n.tc('enterField', 1, { field: i18n.tc('email', 2) }) }, fieldTypeEmail],
}
