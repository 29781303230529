<template>
  <div>
    <el-row v-loading="form.loading" :gutter="15" class="flex-container">
      <el-col :sm="6" class="flex-item">
        <div class="card-container">
          <div class="card-column-no-bordered">
            <div class="card">
              <div class="step">
                <div class="number">
                  <span>
                    <span class="content">1</span>
                  </span>
                </div>
                <div class="title">
                  <span>{{ $tc('dataUser') }}</span>
                </div>
              </div>
              <div class="content">
                <el-form ref="formUser" :model="form.model">
                  <el-row :gutter="20">
                    <el-col :sm="24">
                      <el-form-item
                        :label="$tc('email')"
                        :rules="form.rules.email"
                        prop="email"
                      >
                        <el-input
                          id="id_userEmail"
                          v-model.trim="form.model.email"
                          :maxlength="128"
                          :placeholder="`${$tc('email')}, ${$tc(
                            'exampleUserEmail',
                          )}`"
                          name="email"
                          auto-complete="off"
                          @change="onChangeEmail"
                        >
                          <i
                            slot="prefix"
                            class="el-input__icon fa fa-envelope-square"
                          ></i>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                      <el-form-item
                        :label="$tc('firstName')"
                        :rules="form.rules.firstName"
                        prop="firstName"
                      >
                        <el-input
                          id="id_firstName"
                          v-model="form.model.firstName"
                          :maxlength="64"
                          :placeholder="$tc('firstName')"
                          name="firstName"
                          auto-complete="off"
                        >
                          <i
                            slot="prefix"
                            class="el-input__icon fa fa-portrait"
                          ></i>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                      <el-form-item
                        :label="$tc('lastName')"
                        :rules="form.rules.lastName"
                        prop="lastName"
                      >
                        <el-input
                          id="id_lastName"
                          v-model="form.model.lastName"
                          :maxlength="64"
                          :placeholder="$tc('lastName')"
                          name="lastName"
                          auto-complete="off"
                        >
                          <i
                            slot="prefix"
                            class="el-input__icon fa fa-portrait"
                          ></i>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                      <el-form-item
                        :label="$tc('phoneCell')"
                        :rules="form.rules.phone"
                        prop="phone"
                      >
                        <el-input
                          id="id_phone"
                          v-model.trim="form.model.phone"
                          :controls="false"
                          :placeholder="$tc('phoneCell')"
                          name="phone"
                        >
                          <i
                            slot="prefix"
                            class="el-input__icon fa fa-phone-volume"
                          ></i>
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :sm="6" class="flex-item">
        <div class="card-container">
          <div class="card-column-no-bordered">
            <div class="card">
              <div class="step">
                <div class="number">
                  <span>
                    <span class="content">2</span>
                  </span>
                </div>
                <div class="title">
                  <span>{{ $tc('dataCompany') }}</span>
                </div>
              </div>
              <div class="content">
                <el-form ref="formCompany" :model="form.model">
                  <el-col :sm="24">
                    <el-form-item
                      :label="$tc('type')"
                      :rules="form.rules.company.fields.identificationTypeId"
                      prop="company.identificationTypeId"
                    >
                      <select-identification-type
                        v-model.trim="form.model.company.identificationTypeId"
                        name="identificationTypeId"
                        @input="onValidateIdentificationPattern($event)"
                        @set-identification-type-code="
                          form.model.company.identificationTypeCode = $event
                        "
                        @set-identification-type-name="
                          form.model.company.identificationTypeName = $event
                        "
                      ></select-identification-type>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24">
                    <el-form-item
                      :label="$tc('identification')"
                      :rules="form.rules.company.fields.companyIdentification"
                      prop="company.companyIdentification"
                    >
                      <el-input
                        id="id_companyIdentificationNumber"
                        v-model.trim="form.model.company.companyIdentification"
                        :maxlength="24"
                        :placeholder="$tc('identification')"
                        name="companyIdentification"
                        auto-complete="off"
                        @change="onChangeIdentification"
                      >
                        <i
                          slot="prefix"
                          class="el-input__icon fa fa-building"
                        ></i>
                        <template slot="append">
                          -
                          {{ form.model.company.companyIdentificationCheck }}
                        </template>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24">
                    <el-form-item
                      :label="$tc('companyName')"
                      :rules="form.rules.company.fields.companyName"
                      prop="company.companyName"
                    >
                      <el-input
                        id="id_companyName"
                        v-model="form.model.company.companyName"
                        :maxlength="128"
                        :placeholder="$tc('companyName')"
                        name="companyName"
                        auto-complete="off"
                      >
                        <i
                          slot="prefix"
                          class="el-input__icon fa fa-building"
                        ></i>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24">
                    <el-form-item
                      :label="$tc('contributor')"
                      prop="personContributorKind"
                    >
                      <select-contributor
                        v-model="form.model.person.personContributorKind"
                        name="personContributorKind"
                        @change="onChangeContributorKind"
                      >
                      </select-contributor>
                    </el-form-item>
                  </el-col>
                </el-form>
              </div>
              <div class="content" style="text-align: left">
                <el-checkbox v-model="checkedPolicy">
                  <a
                    href="https://s3.amazonaws.com/cdn.aliaddo.com/contrato-de-prestacion-de-servicios-aliaddo-v28-01-2022.docx.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Términos y condiciones</a>
                </el-checkbox>
                <br />
                <el-checkbox v-model="checkedData">
                  <a
                    href="https://aliaddo.com/privacidad/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Política de Tratamiento de Datos</a>
                </el-checkbox>
              </div>
              <div class="content" style="text-align: left">
                <el-alert
                  v-if="!checkedPolicy || !checkedData"
                  :description="$tc('checkPolicy')"
                  :closable="false"
                  style="word-break: break-word"
                  :center="false"
                  show-icon
                  type="warning"
                >
                </el-alert>
              </div>
              <div class="content">
                <el-button
                  v-if="
                    company.companyServiceFrequencyBilling &&
                    +company.companyServiceFrequencyBilling.length &&
                    +company.planId
                  "
                  size="mini"
                  :disabled="checkedPolicy === false || checkedData === false"
                  class="btn btn-select"
                  @click.prevent="onRegister"
                >
                  <span>
                    {{ $tc('create', 2) }}
                  </span>
                </el-button>
                <el-alert
                  v-else
                  type="error"
                  :closable="false"
                  :title="$tc('frecuencyBillingInvalid')"
                  effect="dark"
                >
                </el-alert>
                <div class="footer">
                  <span
                    style="color: var(--tag-color-text-two)"
                    :class="{ 'text-line-through': isAliaddo }"
                  >
                    <small>
                      <em> {{ $tc('yourIp') }}: {{ form.model.ipAddress }} </em>
                    </small>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import selectContributor from '@/components/selects/select-contributor'
import calculateIdentificationCheck from '@/helpers/identification-check'
import { validateForm } from '@/helpers/messenger'
import { fieldRequiredByBlur } from '@/helpers/validations'
import create from '@/views/common/create'
import form from '@/views/common/form'
import axios from 'axios'
import model, { personEmail as modelPersonEmail } from './model-person'
import rules, { personEmail as rulesPersonEmail } from './rules-person'

export default {
  extends: create('companies', { model }),
  components: {
    selectContributor,
  },
  props: {
    company: { type: Object, required: true },
  },
  data() {
    return {
      form: form({ model, rules }),
      checkedPolicy: false,
      checkedData: false,
      list: {
        files: [],
      },
      loading: {
        personAddress: false,
      },
      model: {
        rut: '',
        response: {},
        userExists: {},
      },
      rules: {
        rulesPersonEmail,
      },
    }
  },
  computed: {
    isAliaddo: {
      get(_this) {
        return _this.form.model.ipAddress === '186.31.27.24'
      },
    },
    uriUploadFile() {
      const uriBase =
        process.env.NODE_ENV === 'production'
          ? 'https://hub.aliaddo.net/api/v1/'
          : 'https://localhost:44358/api/v1/'

      // return `${uriBase}/companies/${model.response.personId}/rut`
      return `${uriBase}companies/251d9dce-e814-40f1-bc16-92de6dd11a08/rut`
    },
  },
  mounted() {
    this.getIp()

    // this.recaptcha()
  },
  methods: {
    onChangeContributorKind(value) {
      const _this = this
      _this.form.model.person.personIsSimpleRegime = false
      _this.form.model.person.personResponsibleFor = 'ZZ'
      if (value === 'RS') {
        _this.form.model.person.personIsSimpleRegime = true
      } else if (value === 'RV') {
        _this.form.model.person.personResponsibleFor = true
      }
    },
    onRemoveAddress(index) {
      this.form.model.person.address.splice(index, 1)
    },
    onAddEmail() {
      this.form.model.person.personEmails.push(modelPersonEmail())
    },
    onRemoveEmail(index) {
      this.form.model.person.personEmails.splice(index, 1)
    },
    onChangeIdentification(event) {
      const _this = this
      _this.form.model.company.companyIdentificationCheck =
        calculateIdentificationCheck(
          _this.form.model.company.companyIdentification,
        )
      _this.onValidateIdentificationPattern(
        _this.form.model.company.identificationTypeId,
      )
    },
    onValidateIdentificationPattern(value) {
      const _this = this
      _this.form.rules.company.fields.companyIdentification = []
      if (value === '41' || value === '42' || value === '50') {
        _this.form.rules.company.fields.companyIdentification.push(
          fieldRequiredByBlur,
          {
            message: _this.$tc('fieldHasInvalidCharacter'),
            // eslint-disable-next-line no-useless-escape
            pattern: /^[a-zA-Z0-9_\-]{4,16}$/,
            required: true,
            trigger: 'blur',
          },
        )
      } else if (value === '13') {
        _this.form.rules.company.fields.companyIdentification.push(
          fieldRequiredByBlur,
          {
            message: _this.$tc('fieldHasInvalidCharacter'),
            // eslint-disable-next-line no-useless-escape
            pattern: /^[1-9][0-9]{3,9}$/,
            required: true,
            trigger: 'blur',
          },
        )
      } else if (value === '21') {
        _this.form.rules.company.fields.companyIdentification.push(
          fieldRequiredByBlur,
          {
            message: _this.$tc('fieldHasInvalidCharacter'),
            // eslint-disable-next-line no-useless-escape
            pattern: /^([a-zA-Z]{1,5})?[1-9][0-9]{3,7}$/,
            required: true,
            trigger: 'blur',
          },
        )
      } else if (value === '12') {
        _this.form.rules.company.fields.companyIdentification.push(
          fieldRequiredByBlur,
          {
            message: _this.$tc('fieldHasInvalidCharacter'),
            // eslint-disable-next-line no-useless-escape
            pattern: /^[1-9][0-9]{4,11}$/,
            required: true,
            trigger: 'blur',
          },
        )
      } else if (value === '31') {
        _this.form.rules.company.fields.companyIdentification.push(
          fieldRequiredByBlur,
          {
            message: _this.$tc('fieldHasInvalidCharacter'),
            // eslint-disable-next-line no-useless-escape
            pattern: /^[1-9]\d{6,9}$/,
            required: true,
            trigger: 'blur',
          },
        )
      } else {
        _this.form.rules.company.fields.companyIdentification.push(
          fieldRequiredByBlur,
        )
      }
    },
    onChangeUpload(file, fileList) {
      this.form.model.person.fileRut = file
    },
    onChangeEmail(event) {
      const _this = this
      if (event && +event.length > 0) {
        _this.form.loading = true
        _this.$http
          .get(`users/${event}/findByEmail`)
          .then(result => {
            _this.model.userExists = result.data
            if (result.data) {
              _this.form.model = {
                ..._this.form.model,
                ...{
                  firstName: result.data.firstName,
                  userLastName: result.data.userLastName,
                  userName: result.data.userName,
                  phone: result.data.userPhone,
                },
              }
            }
          })
          .finally(() => {
            _this.form.loading = false
          })
      } else {
        _this.form.model = {
          ..._this.form.model,
          ...{
            userName: '',
            phone: '',
          },
        }
        _this.model.userExists = {}
      }
    },
    async onRegister() {
      const _this = this
      Promise.all([
        _this.$refs.formUser.validate(),
        _this.$refs.formCompany.validate(),
      ])
        .then(isValid => {
          if (
            isValid.includes(false) ||
            _this.checkedPolicy === false ||
            _this.checkedData === false
          ) {
            validateForm()

            return
          }
          _this.form.model.person.address[0].addressPhone =
            _this.form.model.phone
          _this.form.model = {
            ..._this.form.model,
            userFirstName: _this.form.model.firstName.trim(),
            userLastName: _this.form.model.lastName.trim(),
            userName: _this.form.model.email
              .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
              .trim()
              .slice(0, 23),
            userEmail: _this.form.model.email.trim(),
            ...{
              company: {
                ..._this.company,
                ..._this.form.model.company,
                companyName: _this.form.model.company.companyName.trim(),
                companyIdentification:
                  _this.form.model.company.companyIdentification.trim(),
              },
            },
            seller: _this.$route.query.seller,
            source: _this.$route.query.source,
          }
          if (
            _this.$route.query.source === 'crm' ||
            _this.$route.query.seller
          ) {
            _this.form.model.company.companyServiceSource = 'Crm'
          } else {
            _this.form.model.company.companyServiceSource = 'Site'
          }
          const formData = new FormData()
          formData.append('signup', JSON.stringify(_this.form.model))
          _this.form.loading = true
          _this.$http
            .post('companies', formData)
            .then(response => {
              setTimeout(() => {
                if (process.env.NODE_ENV === 'production') {
                  window.location.replace(response.body)
                }
              }, 3000)
              _this.$message({
                type: 'success',
                message: _this.$tc('processSuccess'),
              })
              if (process.env.NODE_ENV === 'production') {
                _this.$gtag.event('Apertura', {
                  event_category: 'apertura',
                  event_label: 'apertura',
                  value: 1,
                })
              }
            })
            .catch(_ => {
              setTimeout(() => (_this.form.loading = false), 1000)
            })
            .finally(_ => {
              setTimeout(() => (_this.form.loading = false), 1000)
            })
        })
        .catch((error, data) => {
          console.error('%c⧭', 'color: #1d3f73', error, data)
          validateForm(error.stack)
        })
    },
    getIp() {
      axios
        .get('https://api.ipify.org?format=json')
        .then(response => (this.form.model.ipAddress = response.data.ip))
    },

    // async recaptcha () {
    //   // (optional) Wait until recaptcha has been loaded.
    //   await this.$recaptchaLoaded()
    //   // Execute reCAPTCHA with action "login".
    //   await this.$recaptcha('onRegister')
    // }
  },
}
</script>
<style>
.flex-container {
  display: flex;
  flex-direction: row;
  text-align: center;
}
.flex-item {
  padding: 10px;
  flex: 33%;
}
/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
}
</style>
<style lang="scss" scoped>
@import '@/assets/scss/transactions/register.scss';
@import '@/assets/scss/transactions/personalize.scss';
</style>
